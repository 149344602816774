import { DashboardPageKeys } from "./keys";

export const englishValues = {
  [DashboardPageKeys.dashboardPage_whatSurveyString]:
    "What kind of survey would you like to conduct today?",
  [DashboardPageKeys.dashboardPage_createBtn]: "Create",
  [DashboardPageKeys.dashboardPage_comingSoonBtn]: "Coming soon",
  [DashboardPageKeys.dashboardRecentSurvey_recentSurveyString]:
    "Recent surveys",
  [DashboardPageKeys.dashboardRecentSurvey_viewAllString]: "View all",
  [DashboardPageKeys.dashboardPage_howTitle]: "How do I create a survey?",
  [DashboardPageKeys.dashboardPage_howContent]: `Our user-friendly interface ensures you can create a tailored survey experience efficiently. You will find options to create new surveys, view existing ones, and analyse responses. 

Begin by selecting a template that best fits your survey needs. For seamless management of your survey content, our platform allows you to easily enter participant details, customise emails, and track invitations sent, responses received, and any follow-up actions required.

Empower your decision-making process and unlock actionable insights by generating detailed reports from the survey data collected and stored within our platform.`,
  [DashboardPageKeys.dashboardPage_howToCreateString]:
    "How do I create a survey?",
  [DashboardPageKeys.dashboardPage_howToAddString]:
    "How do I add new participants to a campaign?",
  [DashboardPageKeys.dashboardPage_howToRespondString]:
    "How do I respond to a survey?",
  [DashboardPageKeys.dashboardPage_howToViewString]: "How do I view reports?",
};

export const malayValues = {
  [DashboardPageKeys.dashboardPage_whatSurveyString]:
    "Apakah jenis tinjauan yang anda ingin laksanakan hari ini?",
  [DashboardPageKeys.dashboardPage_createBtn]: "Cipta",
  [DashboardPageKeys.dashboardPage_comingSoonBtn]: "Bakal datang",
  [DashboardPageKeys.dashboardRecentSurvey_recentSurveyString]:
    "Tinjauan terbaharu",
  [DashboardPageKeys.dashboardRecentSurvey_viewAllString]: "Tunjukkan semua",
  [DashboardPageKeys.dashboardPage_howTitle]:
    "Bagaimana caranya untuk saya mencipta tinjauan?",
  [DashboardPageKeys.dashboardPage_howContent]: `Antara muka mesra pengguna kami memastikan anda dapat mencipta pengalaman tinjauan yang disesuaikan dengan cekap. Anda akan menemui pilihan untuk mencipta tinjauan baharu, melihat tinjauan sedia ada, dan menganalisis respons.

Mulakan dengan memilih templat yang paling sesuai dengan keperluan tinjauan anda. Untuk pengurusan kandungan tinjauan yang lancar, platform kami membolehkan anda memasukkan butiran peserta dengan mudah, menyesuaikan emel, serta menjejaki jemputan yang dihantar, respons yang diterima, dan tindakan susulan yang diperlukan.

Perkasakan proses membuat keputusan anda dan dapatkan pandangan yang boleh diambil tindakan dengan menghasilkan laporan terperinci daripada data tinjauan yang dikumpulkan dan disimpan dalam platform kami.`,
  [DashboardPageKeys.dashboardPage_howToCreateString]:
    "Bagaimana caranya untuk saya mencipta tinjaun?",
  [DashboardPageKeys.dashboardPage_howToAddString]:
    "Bagaimana caranya untuk saya tambah peserta baharu kepada kempen?",
  [DashboardPageKeys.dashboardPage_howToRespondString]:
    "Bagaimana caranya untuk saya membalas tinjauan?",
  [DashboardPageKeys.dashboardPage_howToViewString]:
    "Bagaimana caranya untuk saya melihat laporan?",
};

export const chineseSimplifiedValues = {
  [DashboardPageKeys.dashboardPage_whatSurveyString]:
    "您今天想进行哪种类型的调查？",
  [DashboardPageKeys.dashboardPage_createBtn]: "创建",
  [DashboardPageKeys.dashboardPage_comingSoonBtn]: "即将推出",
  [DashboardPageKeys.dashboardRecentSurvey_recentSurveyString]: "最新调查",
  [DashboardPageKeys.dashboardRecentSurvey_viewAllString]: "查看全部",
  [DashboardPageKeys.dashboardPage_howTitle]: "如何创建调查？",
  [DashboardPageKeys.dashboardPage_howContent]: `我们用户友好的界面确保您能够高效创建量身定制的调查体验。您可以选择创建新调查、查看现有调查以及分析调查反馈。

首先选择最符合您调查需求的模板。为了便捷地管理您的调查内容，我们的平台允许您轻松输入参与者信息、定制电子邮件，并跟踪已发送的邀请、收到的回复以及需要的后续操作。

通过生成详细的报告，利用平台收集和存储的调查数据，增强您的决策过程并获得可行的洞察。`,
  [DashboardPageKeys.dashboardPage_howToCreateString]: "如何创建调查？",
  [DashboardPageKeys.dashboardPage_howToAddString]: "如何向活动添加新参与者？",
  [DashboardPageKeys.dashboardPage_howToRespondString]: "如何回复调查？",
  [DashboardPageKeys.dashboardPage_howToViewString]: "如何查看报告？",
};

export const chineseTraditionalValues = {
  [DashboardPageKeys.dashboardPage_whatSurveyString]:
    "您今天想進行哪種類型的調查？",
  [DashboardPageKeys.dashboardPage_createBtn]: "創建",
  [DashboardPageKeys.dashboardPage_comingSoonBtn]: "即將推出",
  [DashboardPageKeys.dashboardRecentSurvey_recentSurveyString]: "最新調查",
  [DashboardPageKeys.dashboardRecentSurvey_viewAllString]: "查看全部",
  [DashboardPageKeys.dashboardPage_howTitle]: "如何創建調查？",
  [DashboardPageKeys.dashboardPage_howContent]: `我們用戶友好的介面確保您能夠高效創建量身定制的調查體驗。您可以選擇創建新調查、查看現有調查以及分析調查反饋。

首先選擇最符合您調查需求的模板。為了便捷地管理您的調查內容，我們的平台允許您輕鬆輸入參與者信息、定制電子郵件，並跟蹤已發送的邀請、收到的回覆以及需要的後續操作。

通過生成詳細的報告，利用平台收集和存儲的調查數據，增強您的決策過程並獲得可行的洞察。`,
  [DashboardPageKeys.dashboardPage_howToCreateString]: "如何創建調查？",
  [DashboardPageKeys.dashboardPage_howToAddString]: "如何向活動添加新參與者？",
  [DashboardPageKeys.dashboardPage_howToRespondString]: "如何回覆調查？",
  [DashboardPageKeys.dashboardPage_howToViewString]: "如何查看報告？",
};
