export enum DashboardPageKeys {
  dashboardPage_whatSurveyString = "dashboardPage_whatSurveyString",
  dashboardPage_createBtn = "dashboardPage_createBtn",
  dashboardPage_comingSoonBtn = "dashboardPage_comingSoonBtn",
  dashboardRecentSurvey_recentSurveyString = "dashboardRecentSurvey_recentSurveyString",
  dashboardRecentSurvey_viewAllString = "dashboardRecentSurvey_viewAllString",
  dashboardPage_howTitle = "dashboardPage_howTitle",
  dashboardPage_howContent = "dashboardPage_howContent",
  dashboardPage_howToCreateString = "dashboardPage_howToCreateString",
  dashboardPage_howToAddString = "dashboardPage_howToAddString",
  dashboardPage_howToRespondString = "dashboardPage_howToRespondString",
  dashboardPage_howToViewString = "dashboardPage_howToViewString",
}
