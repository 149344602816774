import { FilePdfFilled, LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Empty, message, Table, Tooltip } from "antd";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PositionsTag from "../../components/PositionsTag";
import SurveyResponseStatusTag from "../../components/SurveyResponseStatusTag";
import {
  GetReportRequest,
  GetReportRequestLayout,
  GetSurveyCampaignBasicInfoResponse,
  GetSurveyCampaignGroupReportResponse,
  GetSurveyResponseBySurveyCampaignIdRequest,
} from "../../constants/apiRequestResponse";
import TEST_IDS from "../../constants/cypressTestIds";
import { SurveyResponse } from "../../constants/types";
import { getReport, getSurveyCampaignBasicInfo } from "../../services/api";
import { FeedbackGroupQuestionReportKeys } from "../../services/i8tn/FeedbackGroupQuestionReport/keys";
import { NormalGroupQuestionReportKeys } from "../../services/i8tn/NormalGroupQuestionReport/keys";
import { getQueryParam, parseTime, setQueryParam } from "../../util/index";
interface Prop {
  report: GetSurveyCampaignGroupReportResponse;
}
export default function FeedbackIndividualView(props: Prop) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [
    getSurveyCampaignBasicInfoResponse,
    setGetSurveyCampaignBasicInfoResponse,
  ] = useState<GetSurveyCampaignBasicInfoResponse | undefined>();
  const [surveyResponseId, setSurveyId] = useState(
    getQueryParam("surveyResponseId") || ""
  );
  const [reportBySurveyResponseId, setReportBySurveyResponseId] = useState<{
    [key: string]: string;
  }>({});
  const [isIndividualReportLoading, setIsIndividualReportLoading] =
    useState(false);
  const [isPrintAsPDFLoading, setIsPrintAsPDFLoading] = useState(false);

  const { t } = useTranslation();

  const getData = useCallback(() => {
    setIsLoading(true);
    const req: GetSurveyResponseBySurveyCampaignIdRequest = {
      surveyCampaignId: getQueryParam("surveyCampaignId"),
    };
    getSurveyCampaignBasicInfo(req)
      .then((res) => {
        if (!res.success) throw new Error(res.message);
        setGetSurveyCampaignBasicInfoResponse(res);
      })
      .catch((err) => {
        message.error(err);
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const onExportPDF = () => {
    setIsPrintAsPDFLoading(true);
    const req: GetReportRequest = {
      surveyCampaignId: getQueryParam("surveyCampaignId") || "",
      format: "pdf",
      surveyResponseId: surveyResponseId,
      layout: GetReportRequestLayout.Individual,
    };
    getReport(req)
      .catch((err) => {
        message.error(err);
      })
      .finally(() => setIsPrintAsPDFLoading(false));
  };

  if (isError) {
    return (
      <div>
        <h1>Oops. Invalid survey campaign</h1>
      </div>
    );
  }

  if (isLoading) {
    return <LoadingOutlined style={{ fontSize: 50 }} />;
  }

  const columns = [
    {
      title: (
        <>
          {t(
            FeedbackGroupQuestionReportKeys.feedbackIndividualView_emailTableHeader
          )}
        </>
      ),
      width: 200,
      render: (rowData: SurveyResponse) => {
        return (
          <span>
            {rowData.surveyEmployee.email} ({rowData.surveyEmployee.team})
          </span>
        );
      },
      sorter: (a: SurveyResponse, b: SurveyResponse) =>
        a.surveyEmployee.email.localeCompare(b.surveyEmployee.email),
    },
    {
      title: (
        <>
          {t(
            FeedbackGroupQuestionReportKeys.feedbackIndividualView_actionsTableHeader
          )}
        </>
      ),
      render: (rowData: SurveyResponse) => {
        return (
          <div
            style={{
              display: "flex",
              gap: 5,
            }}
          >
            <Button
              type="link"
              onClick={() => {
                setQueryParam("surveyResponseId", rowData._id);
                setSurveyId(rowData._id);
                if (reportBySurveyResponseId[rowData._id] !== undefined) {
                  return;
                }

                setIsIndividualReportLoading(true);
                const req: GetReportRequest = {
                  surveyCampaignId: getQueryParam("surveyCampaignId") || "",
                  format: "html",
                  surveyResponseId: rowData._id,
                  layout: GetReportRequestLayout.Individual,
                };
                getReport(req)
                  .then((res) => {
                    setReportBySurveyResponseId((prev) => {
                      return {
                        ...prev,
                        [rowData._id]: res?.data,
                      };
                    });
                  })
                  .catch((err) => {
                    message.error(err);
                  })
                  .finally(() => setIsIndividualReportLoading(false));
              }}
            >
              <>
                {t(
                  FeedbackGroupQuestionReportKeys.feedbackIndividualView_viewReportLink
                )}
              </>
            </Button>
          </div>
        );
      },
    },
  ];

  const participants: SurveyResponse[] =
    getSurveyCampaignBasicInfoResponse?.surveyResponsesByParticipant?.map(
      (sr) => sr.participant
    ) || [];

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        flexDirection: "row",
      }}
    >
      <Card
        style={{
          width: "30%",
          minWidth: 500,
          overflow: "scroll",
        }}
      >
        <Table
          rowKey="_id"
          columns={columns}
          dataSource={participants}
          expandable={{
            expandedRowRender: (participant: SurveyResponse) => {
              const surveyResponses =
                getSurveyCampaignBasicInfoResponse?.surveyResponsesByParticipant?.find(
                  (sr) => {
                    return sr.participant._id === participant._id;
                  }
                )?.surveyResponses || [];
              const peers = surveyResponses?.filter((d) => {
                const isPeer =
                  d.surveyEmployee.participantId ===
                  participant.surveyEmployee._id;

                return isPeer;
              });

              const columns = [
                {
                  title: (
                    <>
                      {t(
                        FeedbackGroupQuestionReportKeys.feedbackIndividualView_emailTableHeader
                      )}
                    </>
                  ),
                  width: 200,
                  render: (rowData: SurveyResponse) => {
                    return (
                      <span>
                        {rowData.surveyEmployee.email} (
                        {rowData.surveyEmployee.team})
                      </span>
                    );
                  },
                  sorter: (a: SurveyResponse, b: SurveyResponse) =>
                    a.surveyEmployee.email.localeCompare(
                      b.surveyEmployee.email
                    ),
                },
                {
                  title: "Status",
                  render: (rowData: SurveyResponse) => {
                    return <SurveyResponseStatusTag status={rowData.status} />;
                  },
                  sorter: (a: SurveyResponse, b: SurveyResponse) =>
                    a.status.localeCompare(b.status),
                },
                {
                  title: (
                    <>
                      {t(
                        FeedbackGroupQuestionReportKeys.feedbackIndividualView_positionTableHeader
                      )}
                    </>
                  ),
                  render: (rowData: SurveyResponse) => {
                    return (
                      <PositionsTag
                        position={rowData.surveyEmployee.position}
                      />
                    );
                  },
                  sortDirection: "ascend",
                  sorter: (a: SurveyResponse, b: SurveyResponse) =>
                    a.status.localeCompare(b.status),
                },
                {
                  title: (
                    <>
                      {t(
                        FeedbackGroupQuestionReportKeys.feedbackIndividualView_lastUpdatedTableHeader
                      )}
                    </>
                  ),
                  width: 200,
                  dataIndex: "updatedAt",
                  render: (time) => parseTime(time),
                },
              ];

              return (
                <Table
                  key={participant._id}
                  rowKey="_id"
                  columns={columns}
                  dataSource={peers}
                />
              );
            },
          }}
        />
      </Card>
      <Card
        style={{
          width: "70%",
        }}
      >
        {isIndividualReportLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LoadingOutlined style={{ fontSize: 30 }} />
          </div>
        ) : surveyResponseId ? (
          <Fragment>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                gap: 20,
                marginBottom: 50,
              }}
            >
              {isPrintAsPDFLoading ? (
                <LoadingOutlined style={{ fontSize: 30 }} />
              ) : (
                <Tooltip
                  title={t(
                    NormalGroupQuestionReportKeys.normalGroupQuestionReport_downloadAsPDFTooltip
                  )}
                >
                  <FilePdfFilled
                    data-testid={
                      TEST_IDS.REPORT_PAGE.group_download_as_pdf_button_id
                    }
                    onClick={(e) => {
                      onExportPDF();
                    }}
                    style={{ fontSize: 30 }}
                  />
                </Tooltip>
              )}
            </div>

            <div
              dangerouslySetInnerHTML={{
                __html: reportBySurveyResponseId[surveyResponseId],
              }}
            ></div>
          </Fragment>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={`Select a team from the left`}
          />
        )}
      </Card>
    </div>
  );
}
