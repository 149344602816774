import {
  ApartmentOutlined,
  LineChartOutlined,
  LoadingOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Card,
  Empty,
  PageHeader,
  Result,
  Statistic,
  Tabs,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SurveyCampaignStatusTag from "../../components/SurveyCampaignStatusTag";
import {
  GetSurveyCampaignBasicInfoRequest,
  GetSurveyCampaignBasicInfoResponse,
} from "../../constants/apiRequestResponse";
import { SurveyTemplateType } from "../../constants/types";
import { getSurveyCampaignBasicInfo } from "../../services/api";
import { ReportsViewKeys } from "../../services/i8tn/ReportsView/keys";
import { getQueryParam, parseTime, setQueryParam } from "../../util/index";
import FeedbackGroupView from "./FeedbackGroupView";
import FeedbackIndividualView from "./FeedbackIndividualView";
import FeedbackTeamView from "./FeedbackTeamView";
import NormalGroupView from "./NormalGroupView";
import styles from "./ReportViewPage.module.css";

export enum Modes {
  groupReport = "groupReport",
  individualReport = "individualReport",
  teamReport = "teamReport",
}

export default function ReportsViewPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState<
    GetSurveyCampaignBasicInfoResponse | undefined
  >();

  const surveyCampaignId = getQueryParam("surveyCampaignId") || "";

  const completionRate = getCompletionRate();
  const { t } = useTranslation();

  function getCompletionRate() {
    if (!report) return 0;
    if (!report.numberOfCompleted) return 0;
    if (!report.numberOfResponse) return 0;
    return Number(
      ((report.numberOfCompleted / report.numberOfResponse) * 100).toFixed(0)
    );
  }

  useEffect(() => {
    setIsLoading(true);

    const req: GetSurveyCampaignBasicInfoRequest = {
      surveyCampaignId: surveyCampaignId,
    };
    getSurveyCampaignBasicInfo(req)
      .then((res) => {
        if (!res.success) throw new Error(res.message);
        setReport(res);
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => setIsLoading(false));
  }, [surveyCampaignId]);

  if (!surveyCampaignId) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <Typography.Title level={5}>Invalid survey campaign</Typography.Title>
      </div>
    );
  }

  if (isLoading) {
    return <LoadingOutlined style={{ fontSize: 50 }} />;
  }

  return (
    <div className="page-content">
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href={`/report`}>
            <>{t(ReportsViewKeys.reportsView_reportsBreadcrumb)}</>
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href={`/report?surveyCampaignId=${surveyCampaignId}`}>
            {`${report?.surveyCampaign?.name}`}
          </a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <PageHeader
        title={
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
            }}
          >
            <span>{`${report?.surveyCampaign?.name}`}</span>
            <SurveyCampaignStatusTag status={report?.surveyCampaign?.status} />
          </div>
        }
        subTitle={`${t(
          ReportsViewKeys.reportsView_lastUpdatedString
        )} ${parseTime(report?.surveyCampaign?.updatedAt)}`}
        onBack={() => {
          window.history.back();
        }}
      />
      <div
        style={{
          display: "flex",
          columnGap: 20,
          rowGap: 20,
          flexDirection: "column",
        }}
      >
        <div className={styles.card_container} style={{ flex: 1 }}>
          <Card className={styles.card}>
            <Statistic
              title={t(ReportsViewKeys.reportsView_totalString)}
              value={report?.numberOfResponse}
              precision={0}
            />
          </Card>
          <Card className={styles.card}>
            <Statistic
              title={t(ReportsViewKeys.reportsView_completedString)}
              value={report?.numberOfCompleted}
              precision={0}
            />
          </Card>
          <Card className={styles.card}>
            <Statistic
              title={t(ReportsViewKeys.reportsView_cancelledString)}
              value={report?.numberOfCancelled}
              precision={0}
            />
          </Card>

          <Card className={styles.card}>
            <Statistic
              title={t(ReportsViewKeys.reportsView_completionRateString)}
              value={`${completionRate} %`}
              precision={0}
            />
          </Card>
        </div>
        {report?.surveyTemplate?.type === SurveyTemplateType.feedback ? (
          // 360 view
          <Tabs
            style={{
              display: "flex",
              width: "100%",
              flex: 5,
            }}
            defaultActiveKey={getQueryParam("layout") || ""}
            onChange={(key) => {
              setQueryParam("layout", key);
            }}
          >
            <Tabs.TabPane
              tab={
                <p>
                  <LineChartOutlined />{" "}
                  {t(ReportsViewKeys.reportsView_groupReportTab)}
                </p>
              }
              key={Modes.groupReport}
            >
              <FeedbackGroupView report={report} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <p>
                  <TeamOutlined />
                  Team Report
                </p>
              }
              key={Modes.teamReport}
            >
              <FeedbackTeamView report={report} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <p>
                  <ApartmentOutlined />
                  {t(ReportsViewKeys.reportsView_individualsReportTab)}
                </p>
              }
              key={Modes.individualReport}
            >
              <FeedbackIndividualView report={report} />
            </Tabs.TabPane>
          </Tabs>
        ) : report?.surveyTemplate?.type === SurveyTemplateType.normal ? (
          <NormalGroupView
            report={report}
            surveyCampaignId={surveyCampaignId}
          />
        ) : (
          <Result
            status="warning"
            title="Error rendering report"
            subTitle="Unsupported survey template"
          />
        )}
      </div>
    </div>
  );
}
