import {
  AgreementCampaign,
  AgreementResponse,
  BasicCompany,
  BasicSurveyCampaign,
  BasicSurveyEmployee,
  BasicSurveyTemplate,
  CategoryGroupedStatistics,
  Company,
  CompanyUser,
  EmailTemplate,
  EmailVariables,
  FeatureFlag,
  FeatureFlagStatus,
  Gender,
  GroupResponse360Statistics,
  GroupResponseStatistics,
  IndividualResponse360Statistics,
  IndividualResponseStatistics,
  IsResetPasswordCodeValidFailureCode,
  QuestionStatistics,
  Response360StatisticsByTeam,
  Roles,
  SurveyCampaign,
  SurveyCampaignStatusLabels,
  SurveyEmployee,
  SurveyQuestion,
  SurveyQuestionResponse,
  SurveyResponse,
  SurveyTemplate,
  TeamStatistics,
} from "./types";

export type SignUpRequest = {
  companyUser?: {
    name: string;
    companyId: string;
    gender: Gender;
    email: string;
    mobile: string;
    password: string;
    role: Roles;
  };
};

export type SignUpResponse = {
  success?: boolean;
  message?: string;
  token?: string;
  expiresIn?: string;
  companyUser?: CompanyUser;
};

export type LoginRequest = {
  email: string;
  password: string;
  companySubdomain: string;
};

export enum LoginResponseErrorCode {
  UNAGREED_AGREEMENT_FOUND = "UNAGREED_AGREEMENT_FOUND",
}
export type LoginResponse = {
  success: boolean;
  errorCode: LoginResponseErrorCode;
  companyUser?: CompanyUser;
  accessToken: string;
  refreshToken: string;
  agreementResponse?: {
    tncFileLink?: string;
    agreementResponseId?: string;
  };
  message?: string;
};

export type GetProfileResponse = {
  success: boolean;
  companyUser?: CompanyUser;
  company?: Company;
  featureFlags?: FeatureFlag[];
  message?: string;
};

export type GetProfileByCompanyIdRequest = {
  companyId?: string;
};

export type GetProfileByCompanyIdResponse = {
  success: boolean;
  message?: string;
  companyUser?: CompanyUser;
  company?: Company;
};

export type GetAllCompanyUserRequest = {};

export type GetAllCompanyUserResponse = {
  success: boolean;
  message?: string;
  companyUsers?: CompanyUser[];
};

export type GetCompanyUsersByCompanyIdRequest = {
  companyId?: string;
};

export type GetCompanyUsersByCompanyIdResponse = {
  success: boolean;
  message?: string;
  companyUsers?: CompanyUser[];
};

export type ChangePasswordRequest = {
  companyUserId?: string;
  oldPassword?: string;
  newPassword?: string;
};

export type ChangePasswordResponse = {
  success: boolean;
  message?: string;
};

export type SendResetPasswordEmailRequest = {
  email?: string;
  subDomain?: string;
};

export type SendResetPasswordEmailResponse = {
  success: boolean;
  message?: string;
};

export type IsResetPasswordCodeValidRequest = {
  token?: string;
};

export type IsResetPasswordCodeValidResponse = {
  success: boolean;
  failureCode?: IsResetPasswordCodeValidFailureCode;
  isValid?: boolean;
  message?: string;
};

export type ResetPasswordRequest = {
  newPassword?: string;
  token?: string;
};

export type ResetPasswordResponse = {
  success: boolean;
  message?: string;
};

export type UpdateCompanyUserRequest = {
  companyUser?: CompanyUser;
};

export type UpdateCompanyUserResponse = {
  success: boolean;
  message?: string;
};

export type RefreshAccessTokenResponse = {
  success: boolean;
  message?: string;
  accessToken?: string;
  refreshToken?: string;
};

export type CreateCompanyRequest = {
  company?: Company;
};

export type CreateCompanyResponse = {
  success?: boolean;
  company?: Company;
  message?: string;
};

export type UpdateCompanyRequest = {
  company?: Company;
};

export type UpdateCompanyResponse = {
  success: boolean;
  message?: string;
};

export type UploadCompanyLogoRequest = {
  base64: string;
};

export type UploadCompanyLogoResponse = {
  success: boolean;
  url?: string;
  message?: string;
};

export type GetCompaniesRequest = {};

export type GetCompaniesResponse = {
  success: boolean;
  companies: Company[];
  message?: string;
};

export type GetCompanyByCompanyIdRequest = {
  companyId?: string;
};

export type GetCompanyByCompanyIdResponse = {
  success: boolean;
  company: Company;
  message?: string;
};

export type GetSurveyTemplatesRequest = {
  name?: string;
  surveyTemplateId?: string;
  active?: boolean;
  isGetDeleted?: boolean;
};

export type GetSurveyTemplatesResponse = {
  page: number;
  success: boolean;
  surveyTemplates: SurveyTemplate[];
  message?: string;
};

export type GetSurveyTemplatesBySurveyTemplateIdRequest = {
  surveyTemplateId?: string;
};

export type GetSurveyTemplatesBySurveyTemplateIdResponse = {
  success: boolean;
  surveyTemplate: SurveyTemplate;
  message?: string;
};

export type GetEmailTemplateBySurveyTemplateTypeRequest = {
  surveyTemplateType?: string;
};

export type GetEmailTemplateBySurveyTemplateTypeResponse = {
  success: boolean;
  emailTemplates?: {
    NORMAL?: string;
    LINE_MANAGER?: string;
    TEAM?: string;
    REPORTS?: string;
  };
  message?: string;
};

export type GetSurveyCampaignsRequest = {
  searchString?: string;
  surveyCampaignStatus?: SurveyCampaignStatusLabels;
  surveyTemplateName?: string;
  limit?: number;
  startCreatedAt?: number;
  endCreatedAt?: number;
};

export type GetSurveyCampaignsResponse = {
  success: boolean;
  surveyCampaigns: SurveyCampaign[];
  surveyTemplates: SurveyTemplate[];
  message?: string;
};

export type GetSurveyCampaignByCompanyIdRequest = {
  companyId?: string;
  limit?: number;
  skip?: number;
  status?: SurveyCampaignStatusLabels | string;
  isGetDeleted?: boolean;
  search?: string;
};

export type GetSurveyCampaignByCompanyIdResponse = {
  success: boolean;
  surveyCampaigns: SurveyCampaign[];
  total?: number;
  message?: string;
};

export type GetSurveyTemplateDetailRequest = {
  surveyTemplateId: string;
};

export type GetSurveyTemplateDetailResponse = {
  success: boolean;
  surveyTemplate: SurveyTemplate | null;
  surveyTemplateQuestions: SurveyQuestion[] | null;
  message?: string;
};

export type GetSurveyCampaignBySurveyCampaignIdRequest = {
  surveyCampaignId?: string;
};

export type GetSurveyCampaignBySurveyCampaignIdResponse = {
  success: boolean;
  surveyCampaign: SurveyCampaign;
  message?: string;
};

export type GetSurveyResponseBySurveyCampaignIdRequest = {
  surveyCampaignId?: string;
};

export type GetSurveyResponseBySurveyCampaignIdResponse = {
  success: boolean;
  surveyResponses: SurveyResponse[];
  message?: string;
};

export type GetSurveyResponseDetailRequest = {
  surveyResponseId?: string;
};

export type GetSurveyResponseDetailResponse = {
  success: boolean;
  message?: string;
  surveyResponse: SurveyResponse;
  participant: BasicSurveyEmployee;
  surveyCampaign: {
    questions: SurveyQuestion[];
    prompt: string;
    status: SurveyCampaignStatusLabels;
  };
};

export type GetSurveyTemplatePreviewRequest = {
  surveyTemplateId: string;
};

export type GetSurveyTemplatePreviewResponse = {
  success: boolean;
  surveyTemplate: SurveyTemplate | null;
  surveyQuestionResponses: SurveyQuestionResponse[] | null;
  message?: string;
};

export type GetSurveyCampaignGroupReportRequest = {
  surveyCampaignId?: string;
};

export type GetSurveyCampaignGroupReportResponse = {
  success: boolean;
  surveyCampaign?: SurveyCampaign;
  surveyTemplate?: SurveyTemplate;
  responseStatistics?: GroupResponseStatistics[];
  message?: string;
  numberOfCompleted?: number;
  numberOfCancelled?: number;
  numberOfResponse?: number;
};

export type GetSurveyCampaignBasicInfoRequest = {
  surveyCampaignId?: string;
};

export type GetSurveyCampaignBasicInfoResponse = {
  success: boolean;
  surveyCampaign?: SurveyCampaign;
  surveyTemplate?: SurveyTemplate;
  numberOfCompleted?: number;
  numberOfCancelled?: number;
  numberOfResponse?: number;
  participantTeams?: {
    team: string;
    numberOfParticipants: number;
  }[];
  surveyResponsesByParticipant?: {
    participant: SurveyResponse;
    surveyResponses: SurveyResponse[];
  }[];

  message?: string;
};

export type GetSurveyCampaignGroupReportV2Request = {
  surveyCampaignId?: string;
};

export type GetSurveyCampaignGroupReportV2Response = {
  success: boolean;
  surveyCampaign?: BasicSurveyCampaign;
  surveyTemplate?: BasicSurveyTemplate;
  categoryGroupedStatistics?: CategoryGroupedStatistics;
  teamStatistics?: TeamStatistics;
  questionStatistics?: QuestionStatistics[];
  highlightedQuestionStatistics?: QuestionStatistics[];
  numberOfCompleted?: number;
  numberOfCancelled?: number;
  numberOfResponse?: number;

  message?: string;
};
export type GetSurveyCampaignIndividualReportRequest = {
  surveyResponseId?: string;
};

export type GetSurveyCampaignIndividualReportResponse = {
  success: boolean;
  surveyCampaign?: SurveyCampaign;
  surveyTemplate?: SurveyTemplate;
  individualResponseStatistics: IndividualResponseStatistics[];
  message?: string;
};

export type GetSurveyCampaign360GroupReportRequest = {
  surveyCampaignId?: string;
};

export type GetSurveyCampaign360GroupReportResponse = {
  success: boolean;
  surveyCampaign?: SurveyCampaign;
  surveyTemplate?: SurveyTemplate;
  responseStatistics?: GroupResponse360Statistics[];
  message?: string;
  numberOfCompleted?: number;
  numberOfCancelled?: number;
  numberOfResponse?: number;
};

export type GetSurveyCampaign360TeamReportRequest = {
  surveyCampaignId?: string;
};

export type GetSurveyCampaign360TeamReportResponse = {
  success: boolean;
  surveyCampaign?: BasicSurveyCampaign;
  surveyTemplate?: BasicSurveyTemplate;
  company?: BasicCompany;
  responseStatisticsByTeam?: Response360StatisticsByTeam;
  message?: string;
  numberOfCompleted?: number;
  numberOfCancelled?: number;
  numberOfResponse?: number;
};

export type GetSurveyCampaign360IndividualReportRequest = {
  surveyResponseId?: string;
};
export type GetSurveyCampaign360IndividualReportResponse = {
  success: boolean;
  surveyResponse: SurveyResponse;
  surveyTemplate: SurveyTemplate;
  /**
   * 17/7/2022 daniel.kwok
   * responseStatistics is only applicable to participants
   * Filter out the rest
   * Participant is defined where surveyEmployee._id === surveyEmployee.participantId
   */
  responseStatistics?: IndividualResponse360Statistics[];
  message?: string;
};

export type GetSurveyResponseBySurveyResponseIdRequest = {
  surveyResponseId?: string;
};

export type GetSurveyResponseBySurveyResponseIdResponse = {
  success: boolean;
  surveyResponse?: SurveyResponse;
  participant?: SurveyEmployee;
  message?: string;
};

export type SaveSurveyResponseAsDraftRequest = {
  surveyResponse?: SurveyResponse;
};

export type SaveSurveyResponseAsDraftResponse = {
  success: boolean;
  message?: string;
};

export type ResendSurveyResponseEmailRequest = {
  surveyResponseIds?: string[];
};

export type ResendSurveyResponseEmailResponse = {
  success: boolean;
  message?: string;
};

export type CreateSurveyCampaignRequest = {
  surveyCampaign?: SurveyCampaign;
};

export type CreateSurveyCampaignResponse = {
  success: boolean;
  surveyCampaign: SurveyCampaign;
  message?: string;
};

export type EnableSurveyTemplateRequest = {
  surveyTemplateId?: string;
};

export type EnableSurveyTemplateResponse = {
  success: boolean;
  message?: string;
};

export type DisableSurveyTemplateRequest = {
  surveyTemplateId?: string;
};

export type DisableSurveyTemplateResponse = {
  success: boolean;
  message?: string;
};

export type UpdateSurveyTemplateRequest = {
  surveyTemplate?: SurveyTemplate;
};

export type UpdateSurveyTemplateResponse = {
  success: boolean;
  message?: string;
};

export type StartSurveyCampaignRequest = {
  surveyCampaignId?: string;
};

export type StartSurveyCampaignResponse = {
  success: boolean;
  message?: string;
};

export type FinishSurveyCampaignRequest = {
  surveyCampaignId?: string;
};

export type FinishSurveyCampaignResponse = {
  success: boolean;
  message?: string;
};

export type DeleteSurveyCampaignRequest = {
  surveyCampaignId?: string;
};

export type DeleteSurveyCampaignResponse = {
  success: boolean;
  message?: string;
};

export type UndeleteSurveyCampaignRequest = {
  surveyCampaignId?: string;
};

export type UndeleteSurveyCampaignResponse = {
  success: boolean;
  message?: string;
};

export type UpdateSurveyCampaignRequest = {
  surveyCampaign?: SurveyCampaign;
};

export type UpdateSurveyCampaignResponse = {
  success: boolean;
  message?: string;
};

export type CreateSurveyResponseFromSurveyCampaignIdRequest = {
  surveyCampaignId?: string;
  surveyEmployees?: SurveyEmployee[];
};

export type CreateSurveyResponseFromSurveyCampaignIdResponse = {
  success: boolean;
  message?: string;
};

export type PrintRequest = {
  url?: string;
};

export type PrintResponse = {
  success: false;
  message?: string;
};

export type GetEmailTemplateByCompanyIdRequest = {
  companyId?: string;
};

export type GetEmailTemplateByCompanyIdResponse = {
  success: boolean;
  message?: string;
  emailTemplates?: EmailTemplate[];
};

export type GetEmailTemplateByEmailTemplateIdRequest = {
  emailTemplateId?: string;
};

export type GetEmailTemplateByEmailTemplateIdResponse = {
  success: boolean;
  message?: string;
  emailTemplate?: EmailTemplate;
};

export type GetEmailTemplateVariablesRequest = {
  _any?: unknown;
};

export type GetEmailTemplateVariablesResponse = {
  success: boolean;
  message?: string;
  emailTemplateVariables?: EmailVariables;
};

export type UpdateEmailTemplateRequest = {
  emailTemplate?: EmailTemplate;
};

export type UpdateEmailTemplateResponse = {
  success: boolean;
  message?: string;
};

export type GetConstantsRequest = {
  keys?: string[];
  parsed?: boolean;
  surveyResponseId?: string;
};

export type GetConstantsResponse = {
  success: boolean;
  message?: string;
  constants?: Record<string, string>;
};

export type GetAgreementCampaignsResponse = {
  success: boolean;
  message?: string;
  agreementCampaigns: AgreementCampaign[];
};

export type UploadTncFileRequest = {
  base64: string;
  filename: string;
};

export type UploadTncFileResponse = {
  success: boolean;
  message?: string;
  presignedURL?: string;
};

export type GetUploadTncFilePresignedUrlRequest = {
  filename?: string;
};

export type GetUploadTncFilePresignedUrlResponse = {
  success: boolean;
  message?: string;
  presignedURL?: string;
};

export type CreateAgreementCampaignRequest = {
  name: string;
  tncFileLink: string;
};

export type CreateAgreementCampaignResponse = {
  success: boolean;
  message?: string;
  agreementCampaign?: AgreementCampaign;
};

export type GetAgreementCampaignByIDRequest = {
  id: string;
};

export type GetAgreementCampaignByIDResponse = {
  success: boolean;
  message?: string;
  agreementCampaign?: AgreementCampaign;
};

export type GetAgreementResponsesByAgreementCampaignIDRequest = {
  agreementCampaignId: string;
};

export type GetAgreementResponsesByAgreementCampaignIDResponse = {
  success: boolean;
  message?: string;
  agreementResponses?: AgreementResponse[];
};

export type AgreeToAgreementResponseRequest = {
  email: string;
  password: string;
  companySubdomain: string;
};

export type AgreeToAgreementResponseResponse = {
  success: boolean;
  message?: string;
  companyUser?: CompanyUser;
  accessToken: string;
  refreshToken: string;
};

export type GetFeatureFlagsByCompanyIdRequest = {
  companyId: string;
};

export type GetFeatureFlagsByCompanyIdResponse = {
  success: boolean;
  message?: string;
  companyId: string;
  featureFlags: FeatureFlag[];
};

export type UpdateFeatureFlagStatusByCompanyIdRequest = {
  companyId?: string;
  featureFlagTemplateId;
  status: FeatureFlagStatus;
};

export type UpdateFeatureFlagStatusByCompanyIdResponse = {
  success: boolean;
  message?: string;
};

export type GetReportRequest = {
  surveyCampaignId?: string;
  format: "html" | "pdf";
  team?: string;
  surveyResponseId?: string;
  layout: GetReportRequestLayout;
};

export enum GetReportRequestLayout {
  Group = "Group",
  Team = "Team",
  Individual = "Individual",
}
